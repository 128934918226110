<template>
  <div class="container privacy-policy-container m-b-110 pt-cookie-title">
    <div class="card p-1 text-first border-0">
      <div class="card-body mx-auto">
        <div class="row update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">{{ $t('cookie.default.pageTitle') }}</h2>
          <div class="col" v-html="$t('cookie.default.detailContent')">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "CookiePolicy",
        mounted() {
          window.scrollTo({top: 0, behavior: "auto"});
        }
    }
</script>

<style scoped>
.privacy-policy-container {
  padding-left: 0;
  padding-right: 0;
  color: #333333;
}

ul {
  padding-left: 1rem;
}

li {
  list-style-type: none;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.update-btn-div {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

  ul {
    padding-left: 0.5rem;
  }

  li p {
    line-height: 20px;
  }

}

@media only screen and (max-width: 576px) {

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }

  .pt-cookie-title {
    padding-top: 1rem;
  }

  .privacy-policy-container {
    margin-top: -61px;
    font-size: 13px;
  }

}
</style>